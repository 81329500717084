var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.deletingBundle, "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    icon: "",
                                    text: "",
                                    color: "secondary",
                                  },
                                },
                                { ...tooltip, ...dialog }
                              ),
                              [_c("v-icon", [_vm._v("delete")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v("Delete")])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.deleteBundleDialog,
        callback: function ($$v) {
          _vm.deleteBundleDialog = $$v
        },
        expression: "deleteBundleDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center w-100" },
              [
                _c("v-icon", [_vm._v("delete")]),
                _c(
                  "span",
                  { staticClass: "text-uppercase secondary--text ml-1" },
                  [_vm._v("delete " + _vm._s(_vm.bundleData.bundle_type_name))]
                ),
                _c("v-spacer"),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c(
                  "v-alert",
                  { attrs: { type: "info", prominent: "", text: "" } },
                  [
                    _vm._v(" Deleting a "),
                    _c("span", { staticClass: "text-lowercase" }, [
                      _vm._v(_vm._s(_vm.bundleData.bundle_type_name)),
                    ]),
                    _vm._v(
                      " does not remove the objects that have been distributed. "
                    ),
                  ]
                ),
                _c("div", { staticClass: "mt-3" }, [
                  _vm._v(" Are you sure you want to delete "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.bundleData.long_id)),
                  ]),
                  _vm._v(" ? "),
                ]),
              ],
              1
            ),
          ]),
          _c("v-card-actions", [
            _c(
              "div",
              { staticClass: "d-flex align-center justify-end w-100" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.deletingBundle,
                      color: "secondary",
                      text: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.deleteBundleDialog = false
                      },
                    },
                  },
                  [_vm._v("close")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      loading: _vm.deletingBundle,
                      text: "",
                      color: "error",
                    },
                    on: { click: _vm.deleteBundle },
                  },
                  [_vm._v("Delete")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }